<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">

                <div id="branch-tab-info">
                    <div class="flex-grow">
                        <div class="vx-col md:w-1/4 sm:w-auto mr-4">
                            <label class="vs-input--label">Chi nhánh</label>
                            <v-select class="" :disabled="isUpdate" v-model="selectedOrganizationBranch"
                                      :clearable="false" :options="organizationBranchOptions"/>
                        </div>
                    </div>

                    <div class="vx-row">
                        <div class="vx-col w-full mb-4 mt-4">
                            <h5>Cài đặt</h5>
                        </div>
                    </div>
                    <div class="vx-row md:mb-1 mb-4">
                        <div class="vx-col lg:w-1/4 w-full">
                            <vs-input class="w-full" data-vv-validate-on="blur" label="Tên nhóm" name="groupName"
                                      v-model="groupName" v-validate="'required|min:4|max:100'"/>
                            <span class="text-danger text-sm">{{ errors.first('groupName') }}</span>
                        </div>
                    </div>
                    <div class="vx-col" v-if="!isUpdate">
                        <feather-icon icon="PlusIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                      @click="addNewRecord(0)"/>
                    </div>

                    <div :key="index" v-for="(item, index) in workShiftSetting" class="mt-3"
                         style="border-bottom: 1px solid #10163a;">
                        <div class="vx-row md:mb-1 mb-4">
                            <div class="vx-col lg:w-1/4 w-full">
                                <vs-input class="w-full" data-vv-validate-on="blur" label="Tên ca" name="workShiftName"
                                          v-model="item.name" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{ errors.first('workShiftName') }}</span>
                            </div>
                            <div class="vx-col lg:w-1/2 md:w-1/2 w-1/2" style="min-width: 65%">
                                <label class="vs-input--label">Ngày làm việc</label>
                                <multiselect
                                    name="workDayOfWeek"
                                    data-vv-validate-on="input"
                                    v-model="item.workDayOfWeekObj"
                                    :options="workDayOfWeekOptions"
                                    :searchable="false"
                                    :multiple="true"
                                    :close-on-select="false"
                                    :allow-empty="false"
                                    placeholder="Select one"
                                    label="label"
                                    track-by="value"
                                    v-validate="'required'"
                                >
                                </multiselect>
                                <span class="text-danger text-sm">{{ errors.first('workDayOfWeek') }}</span>
                            </div>
                        </div>
                        <div class="vx-row md:mb-1 mb-4">
                            <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2">
                                <label class="vs-input--label">Thời gian bắt đầu</label>
                                <v-select :value="item.startHourString" :clearable="false" :options="timeSpans"
                                          data-vv-validate-on="input"
                                          @input="startHour => updateTimeSpan(item, startHour, 'startHour')"
                                          v-validate="'required'" name="startHour"/>
                                <span class="text-danger text-sm">{{ errors.first('startHour') }}</span>
                            </div>
                            <div class="vx-col lg:w-1/6 md:w-1/2 w-1/2">
                                <label class="vs-input--label">Thời gian kết thúc</label>
                                <v-select :value="item.endHourString" :clearable="false" :options="timeSpans"
                                          data-vv-validate-on="input"
                                          @input="endHour => updateTimeSpan(item, endHour, 'endHour')"
                                          v-validate="'required'" name="endHour"/>
                                <span class="text-danger text-sm">{{ errors.first('endHour') }}</span>
                            </div>
                            <div class="vx-col lg:w-1/4 md:w-1/2 w-1/2">
                                <vs-input class="w-full" label="Linh động(phút)" v-model="item.bufferTime"
                                          name="bufferTime" v-validate="'required|numeric'"/>
                                <span class="text-danger text-sm">{{ errors.first('bufferTime') }}</span>
                            </div>
                            <div class="vx-col lg:w-1/4 md:w-1/2 w-1/2">
                                <label class="vs-input--label"></label>
                                <vs-checkbox style="line-height: 35px;" v-model="item.endInNextDay">Làm việc qua ngày
                                </vs-checkbox>
                            </div>
                            <div class="vx-col" style="padding-top: 30px">
                                <feather-icon icon="PlusIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                              v-if="!isUpdate" @click="addNewRecord(index + 1)"/>
                            </div>
                            <div class="vx-col" style="padding-top: 30px">
                                <feather-icon icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                                              v-if="!isUpdate" @click="confirmDeleteRecord(index)"/>
                            </div>
                        </div>
                        <div class="vx-row md:mb-1 mb-4">

                            <div class="vx-col lg:w-1/4 md:w-1/2 w-1/2">
                                <vs-input class="w-full" label="Cho phép check in trước(phút)"
                                          v-model="item.allowCheckInBefore" name="allowCheckInBefore"
                                          v-validate="'required|numeric|max:4|min:1'"/>
                                <span class="text-danger text-sm">{{ errors.first('allowCheckInBefore') }}</span>
                            </div>
                            <div class="vx-col lg:w-1/4 md:w-1/2 w-1/2">
                                <vs-input class="w-full" label="Cho phép check in sau(phút)"
                                          v-model="item.allowCheckInAfter" name="allowCheckInAfter"
                                          v-validate="'required|numeric|max:4|min:1'"/>
                                <span class="text-danger text-sm">{{ errors.first('allowCheckInAfter') }}</span>
                            </div>
                            <div class="vx-col lg:w-1/4 md:w-1/2 w-1/2">
                                <vs-input class="w-full" label="Cho phép check out trước(phút)"
                                          v-model="item.allowCheckOutBefore" name="allowCheckOutBefore"
                                          v-validate="'required|numeric|max:4|min:1'"/>
                                <span class="text-danger text-sm">{{ errors.first('allowCheckOutBefore') }}</span>
                            </div>
                            <div class="vx-col lg:w-1/4 md:w-1/2 w-1/2">
                                <vs-input class="w-full" label="Cho phép check out sau(phút)"
                                          v-model="item.allowCheckOutAfter" name="allowCheckOutAfter"
                                          v-validate="'required|numeric|max:4|min:1'"/>
                                <span class="text-danger text-sm">{{ errors.first('allowCheckOutAfter') }}</span>
                            </div>
                        </div>
                        <div class="vx-row md:mb-1 mb-4">
                            <br>
                        </div>
                    </div>

                    <!-- Save & Reset Button -->
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div class="mt-8  mb-6 flex flex-wrap items-center justify-end">
                                <vs-button class="ml-auto mt-2" @click="saveChanges"
                                           :disabled="!validateForm || workShiftSetting.length == 0">
                                    {{ isUpdate ? 'Cập nhật' : 'Tạo mới' }}
                                </vs-button>
                                <vs-button class="ml-4 mt-2" type="border" color="warning" @click="workShiftList">Quay
                                    lại
                                </vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>

import utils from "../../../components/utils";
import vSelect from 'vue-select';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        vSelect,
        Multiselect
    },
    data() {
        return {
            activeTab: 0,
            workShiftSetting: [],
            isUpdate: false,
            timeSpans: [],
            selectedWorkShiftItem: {},
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
            groupName: null,
            workDayOfWeekOptions: [
                {value: 2, label: 'Thứ 2'},
                {value: 3, label: 'Thứ 3'},
                {value: 4, label: 'Thứ 4'},
                {value: 5, label: 'Thứ 5'},
                {value: 6, label: 'Thứ 6'},
                {value: 7, label: 'Thứ 7'},
                {value: 1, label: 'Chủ nhật'}
            ]
        }
    },
    created() {
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        this.initScreen();
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },

        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value
            }
        }
    },
    methods: {
        updateTimeSpan(setting, hour, type) {
            setting[type] = hour.value;
            setting[`${type}String`] = hour.label;
        },
        updateWorkDay(setting, workDay) {
            setting.workDayOfWeek = workDay.value;
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || 'Chưa xác định';
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]')
            if (branchList.length == 0) {
                return this.$vs.notify({
                    text: 'Hiện tại không tồn tại chi nhánh nào',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            })
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];
            this.loadSetting()
        },
        async saveChanges(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;
            let isCheckValidate = true;
            this.workShiftSetting.forEach(item => {
                item.workDayOfWeek = item.workDayOfWeekObj.map(workDay => workDay.value)
                item.endInNextDay = item.endInNextDay ? 1 : 0;
                item.groupName = this.groupName
                if (item.startHour >= item.endHour && item.endInNextDay == 0) isCheckValidate = false;
            })
            if (!isCheckValidate) {
                return this.$vs.notify({
                    text: "Thời gian kết thúc phải lớn hơn thời gian bắt đầu. ",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            let path = `/work-shift/organization/create/${this.selectedOrganizationBranchValue}`,
                data = this.workShiftSetting;
            if (this.isUpdate) {
                path = `/work-shift/organization/update/${this.$route.query.id}`;
                data = this.workShiftSetting.pop();
            }
            this.$vs.loading();
            this.$crm.post(path, data).then(() => {
                this.$vs.notify({
                    color: 'success',
                    text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} thành công`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$vs.loading.close();
                this.$router.push(`/user/manage-branch-work-shift?branch_id=${this.selectedOrganizationBranchValue}`).catch(() => {
                })
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        loadSetting() {
            let id = this.$route.query.id;
            if (!id) {
                return
            }

            this.$vs.loading();
            let that = this;
            this.$crm.post('/work-shift/organization/find-by-organization-branch/' + this.selectedOrganizationBranchValue).then((response) => {
                let workShiftSetting = response.data.workShiftSetting.filter(item => item.id == id).map(item => {
                    item.startHourString = this.timeSpans.find(timeSpan => timeSpan.value == item.startHour).label;
                    item.endHourString = this.timeSpans.find(timeSpan => timeSpan.value == item.endHour).label;
                    item.workDayOfWeekObj = this.workDayOfWeekOptions.filter(workDay => {
                        return item.workDayOfWeek.includes(workDay.value);
                    })
                    return item;
                });

                if (workShiftSetting.length > 0) {
                    this.isUpdate = true;
                    this.groupName = workShiftSetting[0].groupName
                }
                that.workShiftSetting = workShiftSetting;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        workShiftList() {
            this.$router.push(`/user/manage-branch-work-shift?branch_id=${this.selectedOrganizationBranchValue}`).catch(() => {
            })
        },
        addNewRecord(index) {
            this.workShiftSetting.splice(index, 0, {})
        },
        confirmDeleteRecord(index) {
            this.selectedWorkShiftId = index;
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xoá`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.workShiftSetting.splice(this.selectedWorkShiftId, 1)
        }
    },
}
</script>

<style scoped>

</style>
